<template>
    <div class="position-relative">
        <vue-date-picker
                :id="id"
                :value="value"
                input-class="form-control form-control-lg"
                placeholder="DD / MM / JJJJ"
                format="dd / MM / yyyy"
                @input="input"
        />
        <icon-calendar class="icon"/>
    </div>
</template>

<script>
    import VueDatePicker from 'vuejs-datepicker'

    export default {
        components: {
            VueDatePicker
        },

        props: {
            id: {
                type: String,
                default: ''
            },

            value: {
                type: String,
                default: ''
            }
        },

        methods: {
            input(value) {
                const date = value instanceof Date ? (value.toISOString().split('T')[0]) : value
                this.$emit('input', date)
            }
        }
    }
</script>

<style scoped>
    .icon {
        position: absolute;
        left: 1.25rem;
        top: calc(50% - 11px);
        pointer-events: none;
    }
</style>
