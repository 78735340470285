.side-nav {
    z-index: 5;
    width: 15rem;
    background: $primary;
    position: fixed;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .toggle-wrapper {
        padding: 1rem 1rem 2.75rem;

        button {
            background-color: rgba($rose, .16);
            padding: 0.375rem 0.5rem 0.375rem 0.875rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .toggle-close {
            height: 1.5rem;
            width: 1.5rem;
            background: $primary;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    ul {
        margin-bottom: 0;

        li {
            position: relative;

            a {
                color: rgba($white, .48);
                text-transform: uppercase;
                font-weight: 700;
                font-size: 14px;
                line-height: 11px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-top: 1.125rem;
                padding-bottom: 1.125rem;
                padding-left: 2.25rem;
                text-decoration: none;

                &:hover {
                    background-color: rgba($rose, .16);
                    color: $white;

                    .menu-icon {
                        opacity: 1;
                    }
                }
            }

            a.active {
                color: $white;

                .menu-icon {
                    opacity: 1;
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 0.375rem;
                    background: $rose;
                }
            }

            .menu-icon {
                opacity: .48;
                margin-right: 1.5rem;
            }
        }
    }

    .bottom-wrapper {
        font-weight: 700;
        padding: 1.5rem;

        a {
            color: $white;
            text-decoration: none;
        }

        .help-icon {
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 50%;
            background: $secondary;
            font-weight: 900;
            font-size: 1.5rem;
            color: $blue-light;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
        }
    }
}

.side-nav-toggle {
    z-index: 4;
    position: fixed;
    top: 1rem;
    left: 1rem;
}

.side-nav__badge {
    min-width: 2em;
    min-height: 1.5em;
    line-height: 1em;
    padding: 0.5em;
    color: $white;
    background-color: $red;
    font-size: 10px;
    border-radius: 2em;
    margin-left: 1.5em;
    text-align: center;

    &--top-right {
        position: absolute;
        right: -1em;
        top: -1em;
    }
}

.sidebar-enter-active {
    animation: slide-in .3s;
}

.sidebar-leave-active {
    animation: slide-in .3s reverse;
}

@keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

.sidebar-backdrop {
    transition: opacity 500ms ease-in-out;
    opacity: 0;
}

.sidebar-open .sidebar-backdrop {
    transition: opacity 500ms ease-in-out;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-900, 0.64);
    z-index: 5;
    opacity: 1;
}
