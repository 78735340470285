
.loading {
    position: fixed;
    bottom: 30px;
    left: 0;
}

.error {
    position: fixed;
    bottom: 40px;
    right: 0;
    width: 400px;
    background: white;
    border: 1px solid red;
    color: red;
    min-height: 40px;

    pre {
        overflow: auto;
        font-family: courier, monospace;
    }
}
