$progress-steps: 25, 50, 75, 100;

.improvement-board {
    &__create,
    &__todo,
    &__completed {
        position: relative;
        padding: 2.5rem 0;
        margin-bottom: 1.25rem;

        .improvement:not(:last-of-type) {
            border-bottom: 1px solid rgba($blue, 0.3);
        }
    }

    &__create,
    &__todo {
        box-shadow: 0 3px 8px 0 rgba($black, 0.08);
    }

    &__create {
        padding: 2.5rem;
    }

    &__todo {
        .improvement__wrapper {
            &:hover {
                .improvement__checkbox {
                    label {
                        background-color: darken(#f2f2f2, 10%);
                    }
                }
            }
        }
    }

    &__completed {
        .improvement {
            color: $green;

            &__meta {
                opacity: 1;
            }
        }
    }

    .modal-footer {
        justify-content: center;
    }
}

.improvement {
    cursor: pointer;
    position: relative;

    &__wrapper {
        display: flex;
        align-items: center;
        padding: 1rem 2.5rem;
    }

    &__checkbox {
        display: flex;
        align-items: center;
        margin-right: 2rem;

        label {
            display: inline-flex;

            &.checked {
                background: $secondary;
                @include svg-color($white);
            }

            &.unchecked {
                background: #f2f2f2;
                @include svg-color(#f2f2f2);
            }
        }
    }

    &__action {
        margin-right: auto;
    }

    &__meta {
        opacity: 0.4;
        text-align: right;
        white-space: nowrap;
        margin-left: 1.5rem;
    }

    &__date {
        font-weight: 600;
    }

    &__add {
        display: flex;
        align-items: center;
        color: $green;
        outline: none;
    }

    &__delete {
        display: none;
        position: absolute;
        background: purple;
        @include svg-color(white);
    }

    &:hover {
        .improvement__delete {
            display: inline-flex;
            position: absolute;
            right: -1rem;
            top: 50%;
            transform: translateY(-50%);
            background: $primary;
            @include svg-color(white);
        }
    }

    label {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0;
        cursor: pointer;

        align-items: center;
        justify-content: center;
    }
}
