// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;
    }

    &:focus,
    &.focus {
        box-shadow: none;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: rgba($background, .16);
        border-color: transparent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
        border-color: $active-border;
    }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: rgba-to-hex($color, .2);
    //border-color: rgba($color, 0.2);
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -2px; // border bottom
        height: 4px;
        width: 66.6666667%;
        left: 16.6666667%;
        background: $color;
        transition: all 0.2s;
    }

    &.btn-lg:after {
        width: 75%;
        left: 12.5%;
    }

    &:hover::after {
        width: calc(100% + 4px); // border
        left: -2px;
    }

    &:focus,
    &.focus {
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;

        &:after {
            display: none;
        }
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        &:after {
            display: none;
        }
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    // Manually declare to provide an override to the browser default
    @if $enable-rounded {
        border-radius: $border-radius;
    } @else {
        border-radius: 0;
    }
}
