<template>
    <div>
        <h1>Questions</h1>
        <b-badge variant="info">Assignment: {{ $route.params.assignment }}</b-badge>

        <hr>

        <b-form v-if="questions">
            <b-form-group v-for="question in questions" :key="question.id" :label="question.question"
                          class="form-group">
                <b-form-input :type="question.type"/>
            </b-form-group>
        </b-form>

        <!--<div v-if="questions">
                <div class="form-group" v-for="question in questions">
                    <li>
                        {{question.question}}
                    </li>
                </div>
            </div>-->
        <router-view/>
    </div>
</template>

<script>

    export default {

        name: 'Questions',

        computed: {
            questions() {
                return this.$store.getters.questions
            }
        },

        created() {
            this.$store.dispatch('loadQuestions', this.$route.params.assignment)
        }
    }
</script>

<style scoped>
    h1 {
        color: green;
    }

    .container {
        border: 1px solid black;
    }
</style>
