.assignment-overview {

    &:not(.locked):not(.completed):not(.skipped) {
        .card {
            transform: scale(1.1111);
            z-index: 4;
            background-color: $primary;
            color: $white;
            box-shadow: 0 4px 9px 0 rgba($black, .12);

            .badge {
                margin-bottom: 1.5rem;
                background-color: rgba($white, .32);

                svg {
                    fill: $primary;
                }
            }

            .name {
                font-weight: 900;
                text-transform: uppercase;
                opacity: 1;
            }

            small {
                font-weight: 700;
            }
        }
    }

    &.locked {
        .week-label {
            margin-top: 0.5rem;
        }
    }

    &.completed {
        .card {
            .name {
                font-weight: 900;
                text-transform: uppercase;
                color: $teal;
                opacity: 1;
            }

            .duration {
                color: $gray-800;
                opacity: .5;
                font-weight: 600;
                @include svg-color($gray-800);
            }

            small {
                color: $teal;
            }
        }
    }

    .card {
        position: relative;
        padding: 2rem 1.25rem 1.75rem 1.25rem;
        min-height: 18rem;
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        box-shadow: 0 2px 4px 0 rgba($black, .08);

        .badge {
            margin-bottom: 1rem;

            svg {
                width: 28px;
                height: 28px;
            }
        }

        .name {
            font-weight: 600;
            opacity: .4;
        }

        .duration {
            display: flex;
            //align-items: center;
            justify-content: center;

            svg {
                margin-right: 0.625rem;
            }
        }

        small {
            text-transform: uppercase;
            color: $pink;
            font-weight: 600;
            font-size: 0.75rem;
        }
    }

    // proportional height/width ratio
    // hack all the way
    @media (min-width: map-get($grid-breakpoints, xl)) and (-webkit-max-device-pixel-ratio: 1.5) {
        .card {
            min-height: 23.25rem;
        }
    }
}

.assignment-panel {
    min-width: 22.625rem;
    color: $primary;
    font-size: 0.875rem;

    .row {
        line-height: 48px;

        [class*=col]:first-child {
            font-weight: 700;
        }
    }

    svg {
        margin-right: 22px;
    }

    @include svg-color($primary)
}

.assignment-block {
    padding-top: 4.5rem;
    padding-bottom: 5.75rem;

    .badge {
        font-weight: 700;
    }

    h5 {
        margin-bottom: 0.875rem;
        line-height: 2rem;
    }

    .label {
        margin-bottom: 2rem;
    }

    .assignment-block-body {
        margin-bottom: 2rem;
    }

    .form-wrapper {

        label.control-label {
            font-weight: bold;
            color: $primary;
            margin-bottom: 1.25rem;
        }

        .question-hint {
            color: #9B9B9B;
            font-weight: 600;
            font-size: 0.875rem;
        }

        &.form-wrapper-light,
        &.bg-info-light {
            padding: 2.25rem 2rem 2.25rem 2rem;
            box-shadow: 0 2px 4px 0 rgba($black, .08);
        }

        &.form-wrapper-light {
            background-color: $white;
        }

        .btn-container {
            display: flex;
            justify-content: flex-end;
            padding-top: 1rem;
            height: 100%;
            width: 45px;
        }

        .toggle-delete {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .block-hint {
        color: $primary;
        margin-top: 2rem;
        background-color: rgba-to-hex($primary, .08);
        padding: 1.125rem 1.75rem 1.25rem 1.75rem;
        display: flex;
        font-size: 0.875rem;
        line-height: 1.25rem;
        align-items: center;

        svg {
            margin-right: 1.75rem;
        }
    }

    &.assignment-block-final {
        padding-top: 0;
        position: relative;

        .delimiter {
            margin-bottom: 5.625rem;
        }

        .badge-wrapper {
            position: absolute;
            top: 0;
            left: calc(50% - 7.875rem / 2);
            border-radius: 50%;
            padding: 1.25rem;
        }

        h5 {
            margin-bottom: 2.75rem;
            line-height: 1;
        }

        p.text-center {
            font-size: 1.25rem;
            margin-bottom: 3.375rem;
        }

        .submit-btn {
            margin-bottom: 3.5rem;
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }

        .svg-fill-white .fill {
            fill: $white;
        }

        .svg-fill-info-light .fill {
            fill: $blue-light;
        }

        .form-wrapper {
            margin: 0 auto 3.5rem;
            max-width: 45.75rem;
        }
    }
}
