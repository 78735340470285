@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 300;
    src: url('./../fonts/din-light-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/din-regular-webfont.ttf) format('truetype');
}

@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/din-medium-webfont.ttf) format('truetype');
}

@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/din-bold-webfont.ttf) format('truetype');
}

@font-face {
    font-family: 'Din';
    font-style: normal;
    font-weight: 900;
    src: url(../fonts/din-black-webfont.ttf) format('truetype');
}

// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4 .h5, .h6 {
    line-height: 1;
    color: $purple;
    text-transform: uppercase;
    font-weight: 900;

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
}

h4,
.h4 {
    line-height: 1.125rem // 18px
}

a.disabled {
    cursor: text;
    pointer-events: none;
}

.font-weight-semibold {
    font-weight: 600;
}

.text-lead {
    font-size: 1.125rem;
    line-height: 2rem;
    font-weight: 600;
}

.list-dot {
    position: absolute;
    bottom: 3.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 0;
    z-index: 5;

    li {
        display: inline-block;
        width: 0.875rem; // 14px
        height: 0.875rem; // 14px
        background: rgba($primary, .16);
        border-radius: 50%;

        &.active {
            background: $white;
            border: 0.25rem solid $primary; // 4px
        }
    }
}
