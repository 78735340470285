// Forms
.form-group {
    position: relative;

    &.date-control {
        width: 19.25rem;

        .form-control[readonly] {
            background-color: $input-bg;
        }

        .form-control {
            padding-left: 3.75rem;
            cursor: pointer;
        }
    }
}

// catch here cannot do blending in css (the background color is transparent)
// might be able to solve with a absolute color container, might not be worth the hassle
.bg-info-light, .bg-light, .bg-info {
    > :not(.bg-ignore) {
        .form-control {
            background-color: $white
        }

        .form-group.date-control .form-control[readonly] {
            background-color: $white;
        }
    }
}

.bg-info {
    > :not(.bg-ignore) {
        .form-control {
            border-color: $white;
            box-shadow: 0 8px 16px 0 rgba($black, .12);

            // no focus
            &:focus {
                box-shadow: 0 8px 16px 0 rgba($black, .12);
                border-color: $white;
            }
        }

        .form-control.is-invalid,
        .is-invalid .form-control {
            border: 2px solid $danger;
        }

        .form-control-lg {
            height: 4rem; // 64px
            padding: 0.5rem 1.5rem;
        }
    }
}

.form-control {
    font-weight: 600; // medium

    &:focus {
        box-shadow: inset 0 0 0 1px $blue;
    }
}

.form-control.is-invalid,
.is-invalid .form-control {
    //background-color: rgba($rose, .16);
    background-color: rgba-to-hex($rose, .16);
    border-color: $rose;
    box-shadow: inset 0 0 0 1px $rose;

    &:focus {
        border-color: $danger;
        box-shadow: inset 0 0 0 1px $danger;
    }
}

// TODO: modify this from variables
.form-control-sm {
    height: 2.5rem; // 40px
    padding: 0.25rem 1rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 0.2rem;
}

// Form invalid
.invalid-feedback {
    text-transform: uppercase;
    padding-left: 1rem + 0.125rem; // padding left + border 2px
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600; // medium
    margin-top: 0.3125rem; // 5px
}

.invalid-tooltip {
    top: calc(1.8125rem - 0.5625rem); // 56 /2 - 18/2
    right: 1.125rem; // 18px
    padding: 0.3125rem; // 5px // 18px 1n total
    margin-top: 0;
    line-height: 0;
    border-radius: 50%;
    background-color: $danger;
}

.info-tooltip {
    position: absolute;
    z-index: 5;
    top: calc(1.8125rem - 0.75rem); // 56 /2 - 24/2
    right: 1rem;
    margin-top: 0;
    line-height: 0;
    border-radius: 50%;
    background-color: $green;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.form-control-sm ~ .invalid-tooltip {
    top: calc(1.25rem - 0.5625rem); // 40 /2 - 18/2
    right: 0.625rem; // 10px
}

.is-invalid {
    .invalid-feedback,
    .invalid-tooltip {
        display: block;
    }
}

// Checkboxes
.custom-checkbox, .custom-radio {
    .custom-control-label {
        line-height: 1.75rem; // TODO: find better way to align this
    }

    .custom-control-label::before {
        box-shadow: $custom-control-indicator-focus-box-shadow;
    }

    .custom-control-input:active ~ .custom-control-label {
        &::before {
            background-color: $blue-light;
        }
    }

    .custom-control-input:checked ~ .custom-control-label {
        &::before {
            box-shadow: none;
            background-color: $green;
        }
    }
}

.form-control-label {
    background: $white;
    border: 2px solid rgba-to-hex($blue, .24);
    font-size: 0.875rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    color: $primary;
    font-weight: 900;
    height: initial;
    min-height: 3.5rem;
    word-wrap: break-word;
}

.radio-checkbox {
    padding: 1.5rem 1.375rem;
    background: $white;
    box-shadow: 0 2px 4px 0 rgba($black, .08);
    font-weight: 700;
    color: $primary;
    margin-bottom: 0.5rem;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .controls {
        label {
            width: 2.75rem;
            height: 2.75rem;
            border-radius: 50%;
            margin: 0;
            cursor: pointer;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.checked {
                margin-right: 1rem;
                background: rgba-to-hex($secondary, 0.16);
                @include svg-color(rgba($secondary, 0.16));

                &.active {
                    background: $secondary;
                    @include svg-color($white);
                }
            }

            &.unchecked {
                background: rgba-to-hex($primary, 0.16);
                @include svg-color(rgba($primary, 0.16));

                &.active {
                    background: $primary;
                    @include svg-color($white);
                }
            }
        }
    }
}

.is-invalid > .radio-checkbox {
    margin-bottom: 0;
    border: 1px solid #FDBA33;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    + .invalid-feedback {
        display: flex;
        text-align: center;
        margin-bottom: 1rem;
        background: #FEF4DE;
        border-radius: 0 0 4px 4px;
        padding: 1rem 1.5rem;
        margin-top: 0;

        .icon {
            margin-right: 1.5rem;

            svg {
                fill: #FDBA33;
            }
        }

        span {
            font-size: 14px;
            color: #123552;
            letter-spacing: 0;
            line-height: 24px;
            text-transform: initial;
            font-weight: 400;
        }
    }
}

label.custom-radio-button input[type="radio"] {
    opacity:0;
}

label.custom-radio-button input[type="radio"] ~ .helping-el {
    background-color: #FFFFFF;
    border: 2px solid $primary;
    border-radius: 50%;
    display: inline-block;
    margin-right: 7px;
    padding: 8px;
    position: relative;
    top: 4px;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el {
    border: 2px solid $primary;
}

label.custom-radio-button input[type="radio"]:checked ~ .helping-el:after {
    background-color: $primary;
    border-radius: 50%;
    content: " ";
    font-size: 33px;
    height: 10px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 10px;
}

.radio-container {
    &--active {
        background-color: rgba($primary, 0.08);
        border-radius: 4px;
    }

    .custom-radio-button {
        margin: 10px;
        margin-left: 12px;
    }
}
