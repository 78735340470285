<template>
    <div>Timeline</div>
</template>

<script>
    export default {
        name: 'Timeline'
    }
</script>

<style scoped>
    border:

    1
    px solid red

    ;
</style>
