// Custom styles here
@import "type";
@import "buttons";
@import "forms";
@import "multiselect";
@import "modal";
@import "nav";
@import "badge";
@import "label";
@import "panel";
@import "tour";
@import "alert";
@import "popover";

// pages
@import "pages/landing";
@import "pages/register";
@import "pages/modals";
@import "pages/theme";
@import "pages/assignment";
@import "pages/improvement-board";
@import "pages/lander";

// Ipad pro
@media only screen and (min-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    html {
        font-size: 20px;
    }
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
