.tour-step {
    z-index: 10;
    box-shadow: 2px 2px 24px -6px rgba($black, .16);
    max-width: 28rem;

    .tour-step-inner {
        z-index: 1;
        position: relative;
        border-radius: 4px;
        background-color: rgba($blue-light, 0.8);
        backdrop-filter: blur(16px);
        padding: 1.625rem 2rem;

        p {
            margin-bottom: 2rem
        }

        .v-step__buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    .v-step__arrow {
        z-index: 10;
        width: 2rem;
        height: 2rem;
        position: absolute;

        .v-step__arrow--inner {
            vertical-align: top;
            filter: drop-shadow(2px 4px 8px rgba($black, .16));

            .fill {
                fill: rgba($blue-light, 0.9);
            }
        }
    }
}

.tour-step[x-placement^="top"] {
    margin-bottom: 2rem;
}

.tour-step[x-placement^="top"] .v-step__arrow {
    bottom: -2rem;
    left: 2.625rem;
}

.tour-step[x-placement^="bottom"] {
    margin-top: 2rem;
}

.tour-step[x-placement^="bottom"] .v-step__arrow {
    top: -1rem;
    left: calc(50% - 1rem);
}

.tour-step[x-placement^="right"] {
    margin-left: 2rem;
}

.tour-step[x-placement^="right"] .v-step__arrow {
    left: -2rem;
    top: 2.625rem;
    transform: rotate(90deg);
}

.tour-step[x-placement^="left"] {
    margin-right: 2rem;
}

.tour-step[x-placement^="left"] .v-step__arrow {
    right: -1rem;
    top: calc(50% - 1rem);
}
