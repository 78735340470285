// 1 - Load project variables.
// 2 - Load bootstrap sass files.
// 3 - Load custom app sass files.

// --------------------------------------------------------
//  Import in the default bootstrap variables, functions and mixins.
//  Then override bootstrap default with project specific values.
// --------------------------------------------------------

@import "~bootstrap/scss/functions";
@import "mixins";
@import "variables";
@import "~bootstrap/scss/variables";

// --------------------------------------------------------
// Import required bootstrap sass files needed for this project.
// Uncomment any bootstrap functionality required for this project.
// The idea is to keep this as small as possible.
// --------------------------------------------------------*/

/* --------------------------------------------------------
   Reset and dependencies
   -------------------------------------------------------- */

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/print";



/* --------------------------------------------------------
   Core CSS
   -------------------------------------------------------- */

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";



/* --------------------------------------------------------
   Components
   -------------------------------------------------------- */

@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";



/* --------------------------------------------------------
   Components w/ JavaScript
   -------------------------------------------------------- */

@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";



/* --------------------------------------------------------
   Utility classes
   -------------------------------------------------------- */

@import "~bootstrap/scss/utilities";



/* --------------------------------------------------------
   Custom application specific styles
   -------------------------------------------------------- */

@import "base";
