<template>
    <div class="improvement-create">
        <div class="improvement-create__wrapper">
            <div class="row">
                <div class="col d-flex">
                    <label class="control-label mr-auto">{{ title }}</label>
                    <router-link :to="{ name: 'app.improvement' }" target="_blank" v-if="$route.name !== 'app.improvement'">Bekijk verbeterbord</router-link>
                </div>
            </div>
            <div v-for="(row, index) in internalValue" :key="index" class="row align-items-baseline">
                <div class="col">
                    <form-group>
                        <input v-model="internalValue[index].action" class="form-control form-control-lg" type="text"
                               placeholder="Geef hier je actie op...">
                    </form-group>
                </div>
            </div>

            <div class="row">
                <div class="col d-flex">
                    <button class="btn btn-border-0 improvement__add mr-auto" @click="add"><icon-add-alt :size="20" class="mr-3"/>Nieuwe actie toevoegen</button>
                    <button class="btn btn-primary improvement__submit" :disabled="!internalValue[0].action" @click="submit">Opslaan</button>
                </div>
            </div>

            <div v-if="success || error" class="row mt-3">
                <div class="col d-flex">
                    <span v-if="success" class="d-block text-success text-right w-100">Verbeteractie(s) toegevoegd!</span>
                    <span v-if="error" class="d-block text-danger text-right w-100">Verbeteracties konden niet worden toegevoegd. Probeer opnieuw</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Assignment from '~/model/Assignment';
    import Improvement from '~/model/Improvement';

    export default {
        props: {
            assignment: {
                type: Assignment,
                required: false
            },

            title: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                success: false,
                error: false,
                independent: true,
                internalValue: [
                    { action: null },
                ]
            }
        },

        methods: {
            save() {
                this.$store
                    .dispatch('updateTeam', {teamMembers: this.internalValue.filter(e => e.name && e.email)})
                    .catch(e => this.$store.dispatch('error', e))
            },

            add() {
                this.internalValue.push({name: null, email: null})
            },

            /**
             * Submit the given improvements.
             */
            submit() {
                let improvements = []
                this.success = false
                this.error = false

                // Create a list of promises.
                this.internalValue.forEach(item => {
                    if (item.action) {
                        improvements.push(new Improvement({
                            action: item.action,
                            state: 'active',
                            assignment: this.assignment ? this.assignment.id : undefined
                        }))
                    }
                })

                // Dispatch and clear input on success.
                this.$store.
                    dispatch('postImprovements', improvements)
                    .then(() => {
                        this.internalValue = [{action: null}]
                        this.success = true
                    })
                    .catch(e => {
                        this.$store.dispatch('error', e)
                        this.error = true
                    })
            }
        }
    }
</script>

<style scoped>
    .control-label {
        font-weight: bold;
        color: #65318F;
        margin-bottom: 1.25rem;
    }
</style>
