.label {
    font-size: 0.8125rem;
    font-weight: 700;
    color: $primary;
    line-height: 1.125rem;
    padding: 0.4375rem 1.25rem 0.4375rem 0.5rem;
    background-color: rgba-to-hex($primary, .1);
    border-radius: 4px;
    display: inline-flex;

    svg {
        margin-right: 0.625rem;
    }

    &.label-rounded {
        padding: 0.1875rem 0.625rem 0.1875rem 0.375rem;
        border-radius: 1.5rem;
        align-items: center;

        svg {
            width: 0.875rem;
            height: 0.875rem;
        }
    }
}
