// Register
.register-steps-container {
  .mailto {
    font-size: 1.2rem;

    .underline {
      text-decoration: underline;
    }
  }

  .radio-checkbox {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.username-tooltip {
  position: relative;
  display: inline-block;
  padding-top: 0.25rem;
  margin-right: 0.375rem;

  div {
    width: 100%;
    padding: 0.5625rem 1.25rem;
    background: #eef2f5;
    line-height: 1.125rem;
    font-weight: 700;
    border-radius: 0.25rem;
    color: #65318f;
  }

  &:before {
    content: "";
    background: #eef2f5;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: calc(50% - 0.25rem);
  }
}

.register-next-button {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: calc(25% - 1.5rem);
  z-index: 5;
}

.register-header {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.demo-account {
  left: 200px;
  right: 200px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  position: absolute;
  z-index: 10;
  margin: 20px;
  padding: 20px;

  a {
    color: white;
    font-weight: bold;
  }
}

.fixed-center-baseline {
  position: fixed;
  bottom: 25%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 8;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
}

.register-step-input {
  position: relative;
  width: 28.375rem;
  z-index: 1;
  margin: 0 auto;
}

// Register page
.register-container {
  height: 100vh;
  position: relative;

  .register-account-exists {
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    padding: 2rem;

    &__wrapper {
      background-color: rgba(black, 0.35);
      padding: 0.5rem 0.5rem 0.5rem 3rem;
      border-radius: 0.25rem;

      span {
        font-weight: 300;
        color: $white;
        margin-right: 3rem;
      }
    }
  }

  .form-group:last-of-type {
    margin-bottom: 2.5rem;
  }

  .register-form {
    width: 27rem;
    background: $white;
    padding: 5.625rem 4.625rem 0;
    height: 100%;
    position: relative;

    h2 {
      margin-bottom: 1.625rem;
    }

    p {
      margin-bottom: 2.25rem;
    }

    .register-back-btn {
      position: absolute;
      width: 2.5rem;
      height: 2.5rem;
      top: 1rem;
      left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba-to-hex($black, 0.08);
      border-radius: 50%;
    }
  }

  .register-image {
    position: absolute;
    width: 22.75rem;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
}
