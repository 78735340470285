$progress-steps: 25, 50, 75, 100;

.theme-overview {
    position: relative;
    box-shadow: 0 3px 8px 0 rgba($black, 0.08);
    padding: 3.375rem 3.75rem 3.5rem 2.875rem;
    border-bottom: 4px solid $teal;
    margin-bottom: 1.25rem;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        height: 4px;
        background-color: $green;
        border-bottom-left-radius: 4px;
    }

    .position {
        text-transform: uppercase;
        font-weight: 900;
        color: $blue;
        margin-bottom: 1rem;
    }

    h2 {
        margin-bottom: 3.625rem; //58px

        .first-word {
            color: $blue;
        }
    }

    &.completed {
        &:after {
            width: 100%;
            border-bottom-right-radius: 4px;
        }

        .position, h2 {
            color: $green;
        }
    }

    &.locked {
        .position,
        h2,
        .first-word {
            color: $pink;
        }

        border-color: $pink;
    }

    @each $step in $progress-steps {
        &.progress-#{$step} {
            &:after {
                width: #{$step * 1%};
            }
        }
    }
}

.theme-overview:last-of-type {
    margin-bottom: 0;
}
