.popover {
    box-shadow: 2px 2px 24px -6px rgba($black, .16);
    max-width: 28rem;

    background: none;
    border: transparent;

    .popover-inner {
        z-index: 1;
        position: relative;
        border-radius: 4px;
        background-color: rgba($blue-light, 0.8);
        backdrop-filter: blur(16px);
        padding: 1.625rem 2rem;
        font-size: 1rem;

        p {
            margin-bottom: 2rem
        }
    }

    .arrow {
        width: 2rem;
        height: 2rem;
    }

    .arrow-inner {
        vertical-align: top;
        filter: drop-shadow(2px 4px 8px rgba($black, .16));

        .fill {
            fill: rgba($blue-light, 0.9);
        }
    }
}

.popover .close {
    z-index: 1;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba-to-hex($primary, .16);
    position: absolute;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    opacity: 1;
    padding: 0;
    margin: 0;

    span {
        flex-grow: 1;
    }
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
    left: -2rem;
    transform: rotate(90deg);
}

.popover .arrow::before, .popover .arrow::after {
    display: none;
}

.popover-header {
    border: 0;
}

.popover-header,
.popover-body {
    padding: 0;
}
