// Buttons
.btn {
    text-transform: uppercase;
    font-size: 0.875rem;
    min-width: 10.3125rem;
}

.btn-fluid {
    min-width: initial;
}

.btn-lg {
    min-width: 12.5rem; // 200px
    font-size: 1rem;
}

.btn-100 {
    min-width: 6.25rem !important;
}

.btn-144 {
    min-width: 6.25rem !important;
}

.btn-120 {
    min-width: 7.5rem !important;
}

.btn-175 {
    min-width: 10.9375rem !important;
}

.icon-left {
    margin-right: 0.75rem;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    padding-left: 1rem;
}

.button-link {
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
}

.btn-border-0 {
    border: 0;
    padding-right: 0;
    padding-left: 0;
}

// Primary button colors
.btn-primary {
    &:hover {
        background-color: #845AA5;
        border-color: #845AA5;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    .show > &.dropdown-toggle {
        background-color: #542978;
        border-color: #542978;
    }
}

.btn-outline-light {
    &:after {
        display: none;
    }
}
