// Landing page
.landing-main-image {
    position: absolute;
    top: -2.375rem;
    right: -7rem; // 112px
    width: 46.875rem;
    height: 39.5625rem;
}

.landing-header {
    padding: 1rem;
    display: flex;
    align-items: flex-start;

    .landing-brand {
        padding: 0.75rem;
        font-weight: 900;
        font-size: 0.875rem;
        line-height: 1;
        display: inline-block;
    }

    .btn-help {
        margin-right: 0.625rem;
    }
}

.landing-section-one {
    position: relative;

    h1 {
        margin-bottom: 4.75rem;
        margin-top: 5rem;
    }
}

.landing-section-two {
    position: relative;

    h2 {
        padding-top: 9.3125rem;
        margin-bottom: 2.375rem;
    }

    p {
        width: 25rem;
        margin-bottom: 2.5rem;
    }
}

.landing-section-three {
    position: relative;

    h2 {
        padding-top: 6.75rem;
        margin-bottom: 2.375rem;
    }

    p {
        width: 25rem;
        margin-bottom: 3.625rem;
    }
}

.landing-section-four {
    padding-top: 4rem;
    width: 30.375rem;
    margin: 0 auto;

    h2 {
        margin-bottom: 2.375rem;
    }

    p {
        margin-bottom: 3.0625rem
    }
}

.landing-section-five {
    width: 27.25rem;
    background-color: $white;
    padding: 3.4375rem 4rem;
    border-radius: 0.3125rem;
    box-shadow: 0 2px 4px 0 rgba($black, 0.08);
    margin: 0 auto;

    h2 {
        margin-bottom: 1.5rem;
        font-size: 1.75rem;
    }

    p {
        margin-bottom: 2.5rem;
        font-size: 0.9375rem; // 15
    }

    .form-control {
        border-color: transparent;
        background-color: $gray-200;

        // Placeholder
        &::placeholder {
            color: rgba-to-hex($gray-800, .4);
        }
    }

    .form-group:last-of-type {
        margin-bottom: 1.75rem;
    }

    .btn-block {
        height: 4rem;
        box-shadow: 0 5px 10px 0 rgba($black, .15);
    }
}

.landing-section-one-container {
    height: 43.375rem;
    position: relative;
    overflow: hidden;

    .delimiter {
        position: absolute;
        bottom: 0;
        width: 100%
    }
}

.landing-section-two-container {
    height: 48.8125rem; //781px;

    .mask-one {
        width: 25.125rem; //402px;
        height: 39.875rem; //638px;
        position: absolute;
        top: 0;
        right: -1.125rem; // 18;
        background: url('../../static/landing-first.png') no-repeat;
    }
}

.landing-section-three-container {
    height: 50.4375rem; //807px;

    .mask-two {
        position: absolute;
        top: -3rem; //48px
        left: -4.25rem;
        width: 23.9375rem; //383px;
        height: 42.6875rem; //638px;
        background: url('../../static/landing-second.png') no-repeat;
    }
}

.landing-section-four-container {
    height: 36rem; //576px;
}

.landing-section-five-container {
    padding-bottom: 8rem;

    svg {
        margin-bottom: 3.625rem;
    }
}
