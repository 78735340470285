
.magnifying-image {
    position: absolute;
    right: 0;
    bottom: 0
}

.pill-image {
    position: absolute;
    left: 2.3125rem;
    bottom: 17.6875rem;
    width: 170px;
}

.btn-icon {
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 10;
}

.register-steps-container {
    h1 {
        margin-bottom: 4.25rem
    }
}

.list-dot li {
    pointer-events: none;
}
