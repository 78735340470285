<template>
    <default-layout container-class="bg-info-light">
        <template slot="title">
      <span class="text-white">
        Instellingen:
      </span>
            Alles schoon
        </template>

        <div class="container main-container">
            <div class="bg-white rounded theme-overview">
                <h2 class="h2">
                    <span class="first-word">Profiel</span>
                </h2>

                Als u iets wenst te wijzigen aan uw ingestelde gegevens of wanneer u uw account wenst te verwijderen,
                stuur dan een e-mail naar <a href="mailto:info@leeromgevinghygienischwerken.nl">info@leeromgevinghygienischwerken.nl</a>
            </div>

            <div class="bg-white rounded theme-overview">
                <h2 class="h2">
                    <span class="first-word">Herinneringen en notificaties</span>
                </h2>

                <div class="radio-checkbox" style="box-shadow: none; padding-left: 0;">
                    <div class="flex-grow-1">WhatsApp</div>
                    <div class="controls">
                        <label :class="{ active: optInWhatsapp === true}" class="checked"
                               @click="optInWhatsapp = !optInWhatsapp">
                            <icon-check-alt :size="19"/>
                        </label>
                        <label :class="{ active: optInWhatsapp === false}" class="unchecked"
                               @click="optInWhatsapp = !optInWhatsapp">
                            <icon-times-alt :size="16" :height="19"/>
                        </label>
                    </div>
                </div>

                <div class="radio-checkbox border-top" style="box-shadow: none; padding-left: 0;">
                    <div class="flex-grow-1">SMS</div>
                    <div class="controls">
                        <label :class="{ active: optInSMS === true}" class="checked" @click="optInSMS = true">
                            <icon-check-alt :size="19"/>
                        </label>
                        <label :class="{ active: optInSMS === false}" class="unchecked" @click="optInSMS = false">
                            <icon-times-alt :size="16" :height="19"/>
                        </label>
                    </div>
                </div>

                <div class="d-flex mt-5">
                    <button class="btn btn-primary ml-auto" @click="postOptin">Opslaan</button>
                </div>
            </div>
        </div>

    </default-layout>
</template>

<script>
    import InteractsWithImprovements from '../mixins/InteractsWithImprovements'
    import RadioCheckbox from '../components/question-types/RadioCheckbox'

    export default {
        name: 'Settings',

        components: {RadioCheckbox},

        mixins: [InteractsWithImprovements],

        data() {
            return {
                optInSMS: false,
                optInWhatsapp: false,
                error: false
            }
        },

        mounted() {
            this.$store.dispatch('getUserOptIn', true).then((result) => {
                this.optInSMS = this.search('sms', result).value;
                this.optInWhatsapp = this.search('whatsapp', result).value;
            });
        },

        methods: {
            /**
             * Post Opt-in data.
             */
            postOptin() {
                this.$store
                    .dispatch('updateUserOptIn', {
                        opt_in: [
                            {
                                type: 'whatsapp',
                                value: this.optInWhatsapp
                            },
                            {
                                type: 'sms',
                                value: this.optInSMS
                            }
                        ]
                    })
                    .catch(e => this.$store.dispatch('error', e))
            },

            /**
             * Search for item with give type.
             */
            search(typeKey, myArray) {
                for (var i = 0; i < myArray.length; i++) {
                    if (myArray[i].type === typeKey) {
                        return myArray[i];
                    }
                }
            },
        }
    }
</script>

<style scoped>
    .theme-overview {
        border-bottom: none;
    }
</style>
