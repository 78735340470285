<template>
    <div>

        <form-group :error="hasError(internalValue.address)" style="width: 32.375rem">
            <label slot="label" class="control-label" for="address">Vul hier in op welk adres we de handreiking mogen bezorgen</label>
            <input id="address" v-model="internalValue.address" class="form-control form-control-lg" type="text"
                   placeholder="Adres">
            <span slot="error">Het veld is verplicht</span>
        </form-group>

        <form-group :error="hasError(internalValue.recipient)" style="width: 32.375rem">
            <input id="recipient" v-model="internalValue.recipient" class="form-control form-control-lg" type="text"
                   placeholder="Naam contactpersoon">
            <span slot="error">Het veld is verplicht</span>
        </form-group>

        <div style="width: 32.375rem">
            <div class="row">
                <div class="col-5">
                    <form-group :error="hasError(internalValue.postalCode)">
                        <input v-model="internalValue.postalCode" class="form-control form-control-lg" type="text"
                               placeholder="Postcode">
                        <span slot="error">Het veld is verplicht</span>
                    </form-group>
                </div>

                <div class="col-7">
                    <form-group :error="hasError(internalValue.city)">
                        <input v-model="internalValue.city" class="form-control form-control-lg" type="text"
                               placeholder="Plaats">
                        <span slot="error">Het veld is verplicht</span>
                    </form-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FormWidget from '~/mixins/FormWidget'

    export default {
        mixins: [FormWidget],

        data() {
            return {
                independent: true,
                internalValue: {
                    address: null,
                    recipient: null,
                    postalCode: null,
                    city: null,
                }
            }
        },

        methods: {
            validValue() {
                return this.checkValid(this.internalValue.address) &&
                    this.checkValid(this.internalValue.recipient) &&
                    this.checkValid(this.internalValue.postalCode) &&
                    this.checkValid(this.internalValue.city)
            },

            save() {
                this.$store
                    .dispatch('updateTeam', {special: this.internalValue})
                    .catch(e => this.$store.dispatch('error', e))
            }
        }
    }
</script>

<style scoped>
    hr {
        color: #D6E7F0;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
</style>
