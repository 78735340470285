// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

// Utilities
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/hover";
@import "~bootstrap/scss/mixins/image";
@import "~bootstrap/scss/mixins/badge";
@import "~bootstrap/scss/mixins/resize";
@import "~bootstrap/scss/mixins/screen-reader";
@import "~bootstrap/scss/mixins/size";
@import "~bootstrap/scss/mixins/reset-text";
@import "~bootstrap/scss/mixins/text-emphasis";
@import "~bootstrap/scss/mixins/text-hide";
@import "~bootstrap/scss/mixins/text-truncate";
@import "~bootstrap/scss/mixins/visibility";

// // Components
@import "~bootstrap/scss/mixins/alert";
@import "mixins/buttons";
@import "~bootstrap/scss/mixins/caret";
@import "~bootstrap/scss/mixins/pagination";
@import "~bootstrap/scss/mixins/lists";
@import "~bootstrap/scss/mixins/list-group";
@import "~bootstrap/scss/mixins/nav-divider";
@import "~bootstrap/scss/mixins/forms";
@import "~bootstrap/scss/mixins/table-row";

// // Skins
@import "~bootstrap/scss/mixins/background-variant";
@import "~bootstrap/scss/mixins/border-radius";
@import "~bootstrap/scss/mixins/box-shadow";
@import "~bootstrap/scss/mixins/gradients";
@import "~bootstrap/scss/mixins/transition";

// // Layout
@import "~bootstrap/scss/mixins/clearfix";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/mixins/float";

// Alpha color to solid color on background (rgba - rgb/hex)
@function alpha-color($color, $background: white) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);

    @return $solid-color;
}

// Alpha color to solid color on background (rgba - rgb/hex)
@function rgba-to-hex($color, $opacity, $background: white) {
    $rgba-color: rgba($color, $opacity);
    $solid-color: alpha-color($rgba-color, $background);

    @return $solid-color;
}

@mixin alpha-attribute($attribute, $color, $background: white) {
    $solid-color: alpha-color($color, $background);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

@mixin svg-color($color) {
    svg .fill {
        fill: $color;
    }

    svg .stroke {
        stroke: $color;
    }
}
