// Variables
//
// Use this file to override the Bootstrap defaults
// without modifying key, versioned files.


// Table of Contents
//
// Colors
// Options
// Spacing
// Body
// Links
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-input-box-shadow-xs.

// Colors
$gray-100: #F6FBFE;
$gray-200: #EEF2F5;
$gray-800: #4A4A4A;
$gray-900: #123552;

// primary
$purple:        #65318F;
$purple-light:  #6A4978;
$green:         #1BA099;
$blue:          #8FCCEE;
$blue-light:    #F6FBFE;
$pink:          #D5C7E1;
$rose:          #F7C7D4;
$red:           #E43E6B;
$yellow:        #FFDB8E;
$teal:          #9FCCC9;

$primary:       $purple;
$secondary:     $green;
$info:          $blue;

$theme-colors: (
  "info-light": $blue-light,
);

// Typography
$font-size-base:              1rem !default;
$font-size-lg:                $font-size-base;

$font-family-base:            Din, sans-serif;
// $h3-font-size:                $font-size-base * 1.25; // 20
$h4-font-size:                $font-size-base; // 16

$display3-size:               4rem;
$display3-weight:             900;
$display-line-height:         1;

$display4-size:               3rem;
$display4-weight:             900;


// Buttons
$btn-font-weight:             700;
$btn-border-width:            2px;
$btn-border-radius:           0.25rem; //4px
$btn-disabled-opacity:        1;
$btn-line-height:             1rem; // 16;
$btn-padding-y:               0.875rem;

// Button large
$btn-padding-y-lg:            1rem;
$btn-line-height-lg:          1.25rem; // 20;
$btn-border-radius-lg:        0.25rem; // 4px

// Button small
$btn-padding-y-sm:            0.625rem;
$btn-padding-x-sm:            1.5rem;
$btn-line-height-sm:          1rem; // 16
$btn-border-radius-sm:        0.25rem; // 4px

// Forms
$input-bg:                    $blue-light;
$input-color:                 $gray-900;
//$input-placeholder-color:     rgba($gray-900, .4);
$input-placeholder-color:     rgba-to-hex($gray-900, .4);
$input-plaintext-color:       $gray-900 !default;

$input-focus-bg:              $input-bg;
$input-focus-border-color:    $blue;

$input-height-lg:             3.5rem; // 56px
$input-border-radius-lg:      0.25rem;

// Spacing
$spacer:                      1rem;
$spacers: (
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3)
);

// custom checkboxes
$custom-control-indicator-bg:                   $blue-light;
$custom-control-gutter:                         2rem; // 1rem spacing
$custom-control-indicator-focus-box-shadow:     inset 0 0 1px 1px rgba-to-hex($blue, .32);

// Modals
$modal-sm:                          372px;
$modal-dialog-margin-y-sm-up:       7.5rem;

// Grid
$grid-gutter-width:                 8px;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 888px,
  xl: 1140px
);

// Alerts
$alert-padding-y:                     1rem;
$alert-padding-x:                     2rem;
