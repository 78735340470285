<template>
    <div>
        <img :src="link" width="437">
    </div>
</template>

<script>
    export default {
        props: {
            link: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>
    img {
        padding-bottom: 2px;
    }
</style>
