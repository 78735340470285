.modal-content {
    border: none;
    border-radius: 4px;
    box-shadow: 0 3px 5px 0 rgba($black, .25);
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 0 2rem;
    border: none;
}

.modal-header {
    padding-bottom: 2rem;
    position: relative;
}

.modal-header .close {
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: rgba-to-hex($primary, .16);
    position: absolute;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    opacity: 1;
    padding: 0;
    margin: 0;

    span {
        flex-grow: 1;
    }
}

.modal-footer {
    padding-top: 2.5rem;
    padding-bottom: 3.75rem;
}

.modal-title {
    padding-top: 5.625rem;
    line-height: 1;
}

.modal-open .blur-container {
    filter: blur(16px);
}

.modal-backdrop {
    background-color: rgba($blue, .1);

    &.show {
        opacity: 1;
    }
}

.notify-modal {
    .modal {
        text-align: center;

        &-title {
            padding-top: 1.2rem;
            width: 100%;
            opacity: 0.4;
        }

        &-img {
            img {
                height: 150px;
            }
        }

        .btn {
            @include button-outline-variant($primary);
        }
    }
}
