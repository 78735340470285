.lander {

    &__bg{
        &--one {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
        &-pink-light {
            background-color: #F9F7FA;
        }
    }
    &__section {
        &--hero {
            min-height: 800px;
            height: 80vh;
            position: relative;
            display: flex;
            flex-direction: column;
            background-position: center;
            padding-bottom: 100px;

            @include media-breakpoint-down(sm) {
                min-height: auto;
                height: 45vh;
                background-position: 70% 0%;
                padding-bottom: 0;
            }

            #lottieContainer {
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
            }
        }

        &--intro {
            padding-top: 60px;
            padding-bottom: 100px;

            @include media-breakpoint-down(md) {
                padding-top: 30px;
                padding-bottom: 50px;
            }
        }

        &--text {
            padding-bottom: 55px;

            @include media-breakpoint-down(md) {
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 25px;
            }
        }

        &--movie {
            position: relative;
        }

        &--form {
            padding-top: 140px;
            padding-bottom: 140px;

            @include media-breakpoint-down(md) {
                padding-top: 70px;
                padding-bottom: 70px;
            }
        }
    }

    &__nav {
        padding-left: 0;
        padding-right: 0;

        .landing-brand {
            padding-left: 0;
            color: #ffffff;

            @include media-breakpoint-down(md) {
                padding-left: 16px;
            }
        }
        .btn-outline-light {
            color: #ffffff;
            border-color: rgba(#ffffff, .2);
            display: inline-flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 13px;
                fill: #ffffff;

                * {
                    fill: #ffffff;
                }
            }
        }
    }

    &__hero {
        .hero {
            &__title {
                margin-top: 0;
                font-weight: 900;
                font-size: 53px;
                line-height: 56px;
                text-align: left;
                color: #FCD887;

                @include media-breakpoint-down(lg) {
                    font-size: 39px;
                    line-height: 42px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 27px;
                    line-height: 27px;
                }
            }

            &__text {
                color: rgba(#ffffff, .8);
                width: 80%;
                font-size: 17px;
                line-height: 28px;

                @include media-breakpoint-down(lg) {
                    width: 90%;
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            &__button {
                color: #65318F;
                background-color: #FCD887;
                border-color: #FCD887;
            }
        }
    }

    &__registerinfo {
        padding: 0 12px;

        .registerinfo {
            &__title {
                margin-bottom: 33px;
                font-size: 39px;
                line-height: 41px;

                @include media-breakpoint-down(lg) {
                    font-size: 30px;
                    line-height: 31px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 27px;
                    line-height: 28px;
                }
            }

            &__text {
                width: 90%;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    color: rgba(#572B7A, .8);
                    font-size: 14px;
                    line-height: 20px;

                }

                strong {
                    @include media-breakpoint-down(md) {
                        color: rgba(#009E8F, .8)
                    }
                }
            }

            &__list {
                margin: 33px 0 0 0;
                padding: 0;
                list-style: none;

                li {
                    margin-bottom: 27px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        width: 22px;
                        height: 22px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 26px;
                    }

                    p {
                        font-size: 17px;
                        line-height: 22px;
                        margin: 0;
                        font-weight: 600;

                        @include media-breakpoint-down(lg) {
                            font-size: 13px;
                            line-height: 22px;
                        }

                        @include media-breakpoint-down(md) {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    &__counter {
        display: flex;
        flex-direction: row;
        margin-bottom: 29px;
        align-items: center;

        @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .numbers {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-right: 20px;

            @include media-breakpoint-down(md) {
                justify-content: center;
            }
            @include media-breakpoint-down(md) {
                margin-right: 0px;
            }

            .number {
                height: 60px;
                width: 60px;
                border-radius: 4px;
                background: #fff;
                box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);

                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 28px;
                line-height: 37px;
                font-weight: 800;
                color: #572B7A;
                margin-right: 6px;

                @include media-breakpoint-down(lg) {
                    height: 45px;
                    width: 45px;
                    font-size: 21px;
                    line-height: 28px;
                    margin-right: 5px;
                }

                @include media-breakpoint-down(md) {
                    height: 80px;
                    width: 80px;
                    font-size: 30px;
                    line-height: 48px;
                    margin-right: 6px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &--empty {
                    color: rgba(#572B7A, .2);
                }
            }
        }
        p {
            margin: 0;
            text-transform: uppercase;
            color: #572B7A;
            text-align: left;

            @include media-breakpoint-down(md) {
                margin: 19px 0 0 0;
                text-align: center;
            }
        }
    }

    &__form {
        padding: 47px 45px 53px 45px;
        border-radius: 12px;
        background: #fff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 22px;
            padding: 35px 22px 35px 22px;
            margin: 0 12px;
        }

        .form-group {
            position: relative;
            margin-bottom: 47px;

            label {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 16px;
                color: #572B7A;
                font-weight: 600;
            }

            .form-control {
                border: none;
                background-color: #ffffff;
                padding: 28px 16px 0 0;
                font-size: 18px;
                border-bottom: 2px solid rgba(#572B7A, .1);
                color: #572B7A;

                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }

                &::placeholder {
                    color: rgba(#572B7A, .4);
                }

                &:focus {
                    border-bottom: 2px solid rgba(#572B7A, 1);
                }
            }
        }

        .form__terms {
            margin: 0 0 18px 0;
            font-size: 16px;
            line-height: 18px;
            color: #3C4D74;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            a {
                font-weight: 600;
                color: #572b7a;
            }
        }

        button[type=submit] {
            color: #ffffff;
            background-color: #009E8F;

            &:after {
                content: none;
            }

            &:focus {
                box-shadow: none;
            }
        }

    }

    &__movie {
        margin-bottom: 123px;

        @include media-breakpoint-down(lg) {
            margin-bottom: 80px;
        }

        @include media-breakpoint-down(md) {
            padding: 0 12px;
            margin-bottom: 50px;
        }

        .movie {
            &__title {
                font-size: 30px;
                line-height: 38px;

                @include media-breakpoint-down(md) {
                    font-size: 27px;
                    line-height: 32px;
                }
            }
            &__wrapper {
                position: relative;
                width: 100%;
                padding-top: 56.25%;
            }
            &__embed {
                border-radius: 12px;
                border: 2px solid #C1B1CC;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                //min-height: 580px;
            }

            &__text {
                color: #3C4D74;
                font-size: 17px;
                line-height: 27px;

                @include media-breakpoint-down(lg) {
                    font-size: 13px;
                    line-height: 20px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &__slider {
        padding-bottom: 80px;

        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
        }

        .slide {
            &__wrapper {
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 500px;
                padding-top: 500px;
                position: relative;
                border-radius: 16px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
                overflow: hidden;
                margin: 7px;

                @include media-breakpoint-down(md) {
                    width: 300px;
                    padding-top: 100%;
                    margin: 0px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(rgba(#fff, 0) 0%, #fff 100%);
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 50px;
                    background-color: #fff;
                }
            }

            &__text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 100;
                padding-bottom: 45px;

                @include media-breakpoint-down(md) {
                    padding-bottom: 21px;
                }

                h4 {
                    margin-top: 13px;
                    font-weight: 800;
                    font-size: 30px;
                    line-height: 28px;

                    @include media-breakpoint-down(md) {
                        font-size: 24px;
                        line-height: 25px;
                    }
                }

                .num {
                    background-color: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 56px;
                    height: 56px;
                    margin: 0 auto;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 24px;
                    font-weight: 800;

                    position: relative;

                    @include media-breakpoint-down(md) {
                        font-size: 19px;
                        line-height: 21px;
                        width: 42px;
                        height: 42px;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: -6px;
                        right: -6px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: inherit;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: -11px;
                        width: 24px;
                        height: 25px;
                        transform: translateY(-50%);
                        border-radius: 50%;
                        background-color: inherit;
                    }
                }
            }

            &__one {
                .num {
                    background-color: #8FC8E2;
                }

                h4 {
                    span {
                        color: #8FC8E2;
                    }
                }
            }
            &__two {
                .num {
                    background-color: #9CC6BE;
                }

                h4 {
                    span {
                        color: #9CC6BE;
                    }
                }
            }
            &__three {
                .num {
                    background-color: #F1BCC5;
                }

                h4 {
                    span {
                        color: #F1BCC5;
                    }
                }
            }
            &__four {
                .num {
                    background-color: #FCD887;
                }

                h4 {
                    span {
                        color: #FCD887;
                    }
                }
            }
        }

        .owl-dots {
            margin-top: 45px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            @include media-breakpoint-down(md) {
                margin-top: 24px;
            }

            .owl-dot {
                height: 10px;
                width: 10px;
                background-color: rgba(#572B7A, .2);
                border-radius: 50%;
                margin-right: 22px;

                @include media-breakpoint-down(md) {
                    height: 7px;
                    width: 7px;
                    margin-right: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }


            }

            .active {
                background-color: rgba(#572B7A, 1);
            }
        }
    }

    &__preview {
        position: relative;
        padding: 0 12px;

        .preview {
            &__bg {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                transform: translateX(0);

                @include media-breakpoint-down(md) {
                    width: 200%;
                    transform: translateX(-50%);
                }
            }

            &__wrapper {
                background-color: #ECE7F0;
                border: 1px solid #C1B1CC;
                //height: 675px;
                width: 100%;
                border-radius: 40px 40px 0px 0px;
                position: relative;
                border-bottom-width: 2px;
                padding: 32px 29px 0 29px;
                margin: 0 auto;

                @include media-breakpoint-down(lg) {
                    padding: 23px 21px 0 21px;
                }

                @include media-breakpoint-down(md) {
                    border-radius: 16px 16px 0px 0px;
                    padding: 9px 8px 0 8px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    height: 2px;
                    width: 54px;
                    transform: translateX(-100%);
                    background-color: #C1B1CC;

                    @include media-breakpoint-down(md) {
                        width: 2px;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    right: 0;
                    height: 2px;
                    width: 54px;
                    transform: translateX(100%);
                    background-color: #C1B1CC;

                    @include media-breakpoint-down(md) {
                        width: 2px;
                    }
                }
            }

            &__slider {
                .owl-stage-outer {
                    border-radius: 24px 24px 0px 0px;

                    @include media-breakpoint-down(md) {
                        border-radius: 10px 10px 0px 0px;
                    }
                }

            }
            &__slide {
                padding-top: 57%;
                width: 100%;
                background-size: cover;
                background-position: top center;
                background-repeat: no-repeat;
                //border-radius: 24px 24px 0px 0px;

                @include media-breakpoint-down(md) {
                    //border-radius: 10px 10px 0px 0px;
                }
            }
        }
    }

    &__text {
        &--pink {
            color: #3C4D74;
        }
        &--o{
            &8 {
                opacity: 0.8;

                @include media-breakpoint-down(md) {
                    opacity: 1;
                }
            }
        }
    }

    &__footer {
        background-color: #572B7A;

        @include media-breakpoint-down(md) {
            opacity: 1;
        }

        img {
            &:first-child {
                margin-top: -1px;
            }
        }

        .footer {
            &__title {
                font-weight: 900;
                font-size: 48px;
                line-height: 48px;
                text-align: center;
                color: #fff;
                margin-bottom: 48px;

                @include media-breakpoint-down(md) {
                    font-size: 30px;
                    line-height: 30px;
                }
            }

            &__infowrapper {
                display: flex;
                flex-direction: row;
                @include media-breakpoint-down(md) {
                    padding: 80px 12px 0 12px;
                }
            }

            &__box {
                margin: auto;
                text-align: center;
                padding: 51px 80px 62px 80px;
                color: #ffffff;
                flex: 0 0 50%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                @include media-breakpoint-down(md) {
                    padding: 35px 22px 35px 22px;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                }

                &--left {
                    border-radius: 16px 0px 0px 16px;
                    background: rgba(#ffffff, .2);
                    margin-right: 1px;

                    @include media-breakpoint-down(sm) {
                        border-radius: 16px;
                    }
                }
                &--right {
                    border-radius: 0px 16px 16px 0px;
                    background: rgba(#ffffff, .12);
                    margin-left: 1px;
                }

                .box {
                    &__title {
                        font-weight: 600;
                        @include media-breakpoint-down(md) {
                            font-weight: 800;
                        }
                    }
                    &__text {
                        margin-bottom: 32px;
                    }
                    &__button {
                        margin-top: auto;

                        .btn {
                            &:hover {
                                border-color: #FCD887;
                                color: #FCD887;
                            }
                        }
                    }
                }
            }

            &__share {
                display: flex;
                flex-direction: column;
                margin-top: 70px;

                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                }

                .share {
                    &__title {
                        text-align: center;
                        color: #ffffff;
                        font-weight: normal;
                        margin-bottom: 36px;
                    }
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;

                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    li {
                        margin-right: 34px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            &__terms {
                margin-top: 85px;
                border-top: 1px solid rgba(#ffffff, .1);
                text-align: center;
                padding-top: 25px;
                padding-bottom: 85px;

                @include media-breakpoint-up(sm) {
                    padding-bottom: 25px;
                }

                a {
                    color: rgba(#fff, .4);

                    &:hover {
                        cursor: pointer;
                        color: rgba(#fff, 1);
                    }
                }
            }
        }
    }

    &__modal {
        .modal-dialog {
            max-width: 700px;
            //margin: 0 auto;
            display: flex;
            max-height: calc(100% - 1rem);

            .modal-content {
                border-radius: 12px;
            }

            .modal-header {
                padding: 0 20px 10px 20px;

                .modal-title {
                    padding-top: 3rem;
                }
            }

            .modal-body {
                overflow-y: auto;
                padding: 0 20px;
            }

            .modal-footer {
                padding: 10px 0 0 0;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;

                .btn {
                    background-color: #572B7A;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;

                    border-color: #572B7A;
                }
            }
        }
    }
}

.mobile-button-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.12);
    z-index: 9999;

    @include media-breakpoint-up(sm) {
        display: none;
    }

    .btn {
        width: 100%;
        background-color: #009E8F;
        text-align: center;
        color: #ffffff;

        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(#009E8F, 0.25);
        }

    }
}


#registerSuccessModal {

    .modal-content {
        border-radius: 12px;
    }

    .modal-header {
        padding-bottom: 0;
        z-index: 10;
    }
    .modal-title {
        padding-top: 0;
        padding-bottom: 24px;
        margin-top: 15px;
    }

    .modal-body {
        #modalLottieContainer {
            width: 50%;
            margin: 0 auto;
            transform: translateX(28%);
        }

        p {
            @include media-breakpoint-down(md) {
                font-size: 15px;
            }
        }

        .form-control-label {
            margin-bottom: 15px;
        }

        .modal-footer-info {
            background-color: #FFF3D8;
            color: #887D60;
            margin-bottom: 40px !important;
            margin-top: 42px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 17px;
            padding: 15px 26px 10px 26px;
        }
    }
}
