// background select

.bg-info {
    > :not(.bg-ignore) {
        .multiselect__select {
            display: none;
        }

        .multiselect__content-wrapper {
            box-shadow: 0 8px 16px 0 rgba($black, .12);
            top: calc(4rem - 1px); // height of the input
            border: 0;
            border-top: 1px solid #e4f3fb;
        }

        .multiselect--above .multiselect__content-wrapper {
            box-shadow: 0  -8px 16px 0 rgba($black, .12);
            bottom: calc(4rem - 1px);
            top: inherit;
        }

        .multiselect__element .multiselect__option {
            padding: 1.2rem 1.5rem;
        }

        .multiselect__element {
            &:after {
                width: calc(100% - 3rem);
                left: 1.5rem;
            }
        }

        .multiselect__tags > span {
            line-height: 3rem;
        }
    }
}

.form-control-sm {
    .multiselect__select {
        width: 1.5rem;
        height: 1.5rem;
        right: .25rem + 0.125rem; // add borders 2px
        top: calc(2.375rem / 2 - 1.5rem / 2);

        svg {
            width: 0.6875rem;
        }
    }

    .multiselect__element .multiselect__option {
        padding: 0.625rem 1rem;
    }

    .multiselect--above .multiselect__content-wrapper {
        bottom: calc(2.5rem - 1px) !important;
        top: inherit !important;
    }

    .multiselect__content-wrapper {
        top: calc(2.5rem - 1px); // height of the input
    }

    .multiselect__tags > span {
        line-height: 2rem;
    }
}

// borders when open
.multiselect--active:not(.multiselect--empty) {
    &:not(.multiselect--above) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.multiselect--above {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}

.multiselect--empty {
    .multiselect__content-wrapper {
        display: none;
    }
}

.multiselect--above .multiselect__content-wrapper {
    bottom: calc(3.5rem - 1px);
    top: inherit;
}

.multiselect__element .multiselect__option {
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 1.125rem 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.multiselect__element {
    position: relative;

    &:after {
        content: '';
        height: 1px;
        display: block;
        width: calc(100% - 2rem);
        left: 1rem;
        position: absolute;
        bottom: 0;
        background: rgba-to-hex($blue, .24);
    }

    &:nth-last-child(2):after {
        display: none;
    }
}

// main styles
fieldset[disabled] .multiselect {
    pointer-events: none;
}

.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block;
}

.multiselect__spinner:before,
.multiselect__spinner:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border-color: $green transparent transparent;
    border-style: solid;
    border-width: 2px;
    box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
}

.multiselect__spinner:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
    opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
    touch-action: manipulation;
}

.multiselect {
    position: relative;
}

.multiselect * {
    box-sizing: border-box;
}

.multiselect:focus {
    outline: none;
}

.multiselect--disabled {
    pointer-events: none;
    opacity: 0.6;
}

.multiselect--active {
    z-index: 50;
    // focus
    border-color: $input-focus-border-color;
    box-shadow: inset 0 0 0 1px $input-focus-border-color;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select svg {
    transform: rotateZ(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
    position: relative;
    display: inline-block;
}

.multiselect__input {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    background: transparent;
    font-weight: 600;
}

.form-control-lg .multiselect__input {
    font-size: 1rem;
    line-height: 1.5;
}

.multiselect__input::placeholder,
.multiselect--placeholder {
    color: $input-placeholder-color;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
    width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
    border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none;
}

.multiselect__tags-wrap {
    display: inline
}

.multiselect__tags {
    height: 100%;
}

.multiselect__tags > span {
    height: 100%;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    line-height: 2.5rem;
    display: inline-block;
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: $green;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: initial;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
}

.multiselect__tag-icon:after {
    content: "×";
    color: #266d4d;
    font-size: 14px;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #369a6e;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
    color: white;
}

.multiselect__current {
    line-height: 16px;
    min-height: 40px;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    padding: 8px 12px 0;
    padding-right: 30px;
    white-space: nowrap;
    margin: 0;
    text-decoration: none;
    border-radius: 5px;
    border: 1px solid #E8E8E8;
    cursor: pointer;
}

.multiselect__select {
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    width: 2rem;
    height: 2rem;
    padding: 0.25rem 0.5rem;
    margin: 0;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
    background: $green;
    border-radius: 0.125rem;
    right: .5rem + 0.125rem; // add borders 2px;
    top: calc(3.375rem / 2 - 2rem / 2);
}

.multiselect__placeholder {
    color: #ADADAD;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
}

.multiselect--active .multiselect__placeholder {
    display: none;
}

.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: calc(100% + 2px); // border
    max-height: 240px;
    overflow: auto;
    border: 1px solid #E8E8E8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
    left: -1px; // border
    top: calc(3.5rem - 1px); // height of the input
}

.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    width: 100%;
    vertical-align: top;
}

.multiselect--above .multiselect__content-wrapper {
    bottom: calc(100% + 1px);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #E8E8E8;
}

.multiselect__content::webkit-scrollbar {
    display: none;
}

.multiselect__element {
    display: block;
}

.multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}

.multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
}

.multiselect__option--highlight {
    background: rgba-to-hex($blue, .16);
    outline: none;
    color: $input-color;
}

.multiselect__option--highlight:after {
    content: attr(data-select);
    background: $green;
    color: white;
}

.multiselect__option--selected {
    background: #F3F3F3;
    color: #35495E;
    font-weight: bold;
}

.multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: rgba-to-hex($blue, .16);
    //color: #fff;
}

.multiselect__option--selected.multiselect__option--highlight:after {
    background: $green;
    content: attr(data-deselect);
    color: #fff;
}

.multiselect--disabled {
    background: #ededed;
    pointer-events: none;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
}

.multiselect__option--disabled {
    background: #ededed;
    color: #a6a6a6;
    cursor: text;
    pointer-events: none;
}

.multiselect__option--group {
    background: #ededed;
    color: #35495E;
}

.multiselect__option--group.multiselect__option--highlight {
    background: #35495E;
    color: #fff;
}

.multiselect__option--group.multiselect__option--highlight:after {
    background: #35495E;
}

.multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
    background: $green;
    color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: $green;
    content: attr(data-deselect);
    color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
    transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
    opacity: 0;
}

.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
}

*[dir="rtl"] .multiselect {
    text-align: right;
}

*[dir="rtl"] .multiselect__select {
    right: auto;
    left: 1px;
}

*[dir="rtl"] .multiselect__tags {
    padding: 8px 8px 0px 40px;
}

*[dir="rtl"] .multiselect__content {
    text-align: right;
}

*[dir="rtl"] .multiselect__option:after {
    right: auto;
    left: 0;
}

*[dir="rtl"] .multiselect__clear {
    right: auto;
    left: 12px;
}

*[dir="rtl"] .multiselect__spinner {
    right: auto;
    left: 1px;
}

@keyframes spinning {
    from {
        transform: rotate(0)
    }
    to {
        transform: rotate(2turn)
    }
}
