.alert {
    font-size: 0.875rem;
    line-height: 1.5rem;
    display: flex;
    align-items: center;
}

.alert-warning {
    color: $gray-900;
    padding-left: 1.5rem;

    svg {
        margin-right: 1.5rem;
    }

    span {
        flex-grow: 1;
    }

    @include svg-color(#FDBA33)
}

