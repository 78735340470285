#loginModal p {
    margin-bottom: 2.5rem;
}

#loginSuccessModal,
#registerSuccessModal {
    .modal-body {
        p {
            margin-bottom: 1.625rem;
        }

        .form-control-label {
            margin-bottom: 2.125rem;
        }

        p:last-of-type {
            margin-bottom: 3.125rem;
        }
    }
}
