<template>
    <default-layout container-class="bg-info-light improvement-board">
        <template slot="title">
             <span class="text-white">
                Verbeterbord
            </span>
        </template>

        <template slot="right">
            <button v-if="0 < improvements.length" class="btn btn-warning pl-5 pr-5" @click="downloadPdf">Download PDF</button>
        </template>

        <div class="bg-white rounded improvement-board__create">
            <ImprovementCreate title="Vul hier nieuwe acties in"></ImprovementCreate>
        </div>

        <div v-if="0 < activeImprovements.length" class="bg-white rounded improvement-board__todo">
            <transition-group name="slide-fade">
                <Improvement v-for="improvement in activeImprovements"
                             :key="improvement.id"
                             :improvement="improvement"
                             @delete="showDeleteModal"/>
            </transition-group>
        </div>

        <div v-if="0 < completedImprovements.length" class="rounded improvement-board__completed">
            <transition-group name="slide-fade">
                <Improvement v-for="improvement in completedImprovements"
                             :key="improvement.id"
                             :improvement="improvement"
                             @delete="showDeleteModal"/>
            </transition-group>
        </div>

        <b-modal ref="delete-modal" title="Weet je het zeker?" @ok="deleteImprovement" ok-title="Ja" cancel-title="Nee" >
            <p>Je staat op het punt on dit verbeterpunt te verwijderen. Dit kan niet ongedaan worden gemaakt.</p>
        </b-modal>
    </default-layout>
</template>

<script>
    import * as jsPDF from 'jspdf';
    import 'jspdf-autotable';

    import Improvement from '~/components/improvement/Improvement'
    import ImprovementCreate from '~/components/improvement/ImprovementCreate'
    import InteractsWithTeam from '~/mixins/InteractsWithTeam'
    import InteractsWithImprovements from '~/mixins/InteractsWithImprovements'

    export default {

        components: {ImprovementCreate, Improvement},

        mixins: [InteractsWithTeam, InteractsWithImprovements],

        data() {
            return {
                improvementToDelete: Improvement,
            }
        },

        created() {
            this.$store.dispatch('loadImprovements', true)
        },

        methods: {
            showDeleteModal(improvement) {
                this.improvementToDelete = improvement
                this.$refs['delete-modal'].show()
            },

            deleteImprovement() {
                this.$store.dispatch('deleteImprovement', this.improvementToDelete)
            },

            /**
             * Generate a PDF for all active / completed improvements.
             */
            downloadPdf () {
                var doc = new jsPDF('landscape')

                let currentDate = new Date();
                let title = `Verbeterbord - Team ${this.team.teamName} - ${currentDate.getDate()}/${currentDate.getMonth()}/${currentDate.getFullYear()}`

                // PDF table config
                doc.autoTableSetDefaults({
                    headStyles: {fillColor: [101, 49, 143]}, // Purple
                    margin: {top: 25},
                    didDrawPage: function(data) {
                        doc.setFontSize(20);
                        doc.text(title, data.settings.margin.left, 20);
                    }
                });

                // Create table for active improvements.
                doc.autoTable(
                    this.generateTable(
                        `Acties (${this.activeImprovements.length})`,
                        this.getFormattedImprovements(this.activeImprovements)
                    )
                )

                // Create table for completed improvements.
                doc.autoTable(
                    this.generateTable(`Afgeronde acties (${this.completedImprovements.length})`,
                        this.getFormattedImprovements(this.completedImprovements)
                    )
                )

                doc.save(`${title}.pdf`)
            },

            /**
             * Format the list for Autotable JSPDF.
             * @param list
             */
            getFormattedImprovements(list) {
                return list.map(improvement => {
                    return [
                        improvement.action,
                        improvement.dateCreated,
                    ]
                })
            },

            /**
             * Generate a table
             * @param title
             * @param body
             */
            generateTable(title, body) {
                return {
                    head: [[title, 'Datum aangemaakt']],
                    body: body,
                    // Default for all columns
                    pageBreak: 'avoid',
                    bodyStyles: {valign: 'top'},
                    styles: {cellWidth: 'wrap', rowPageBreak: 'auto', halign: 'justify', overflow: 'linebreak'},
                    columnStyles: {text: {cellWidth: 'auto'}, 0: {cellWidth: 100}}
                }
            }
        }
    }
</script>

<style>
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(10px);
        opacity: 0;
    }

    .modal-footer button {
        width: 100%;
    }
</style>
