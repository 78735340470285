<template>
    <div>
        <div class="bg-info position-relative" style="height: 28.25rem">
            <img class="hands-image" src="~static/hands.png" alt="">
            <delimiter class="delimiter"/>

            <div class="container title-container">
                <div class="row">
                    <div :class="{ 'col-5': hasRight, 'col-12': !hasRight }">
                        <h1 class="display-4">
                            <slot name="title">
                <span class="text-white">
                  Title
                </span>
                                <br>
                                Subtitle
                            </slot>
                        </h1>

                        <slot name="body"/>
                    </div>

                    <div v-if="hasRight" class="col-7 text-right" style="z-index: 5">
                        <slot name="right"/>
                    </div>
                </div>
            </div>
        </div>

        <div :class="containerClass" :style="{ 'min-height': minHeight }">
            <div :class="classes">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
    import Delimiter from './svgs/Delimiter'

    export default {
        components: {Delimiter},

        props: {
            fluid: {
                type: Boolean,
                default: false
            },

            containerClass: {
                type: String,
                default: ''
            },

            hasRight: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            classes() {
                return {
                    'container': !this.fluid,
                    'main-container': !this.fluid
                }
            },

            minHeight() {
                return this.fluid ? 'calc(100vh - 28.25rem)' : 'calc(100vh - 28.25rem + 9.25rem)'
            }
        }
    }
</script>

<style scoped>
    h1 {
        margin-bottom: 2.125rem;
    }

    .hands-image {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .delimiter {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .title-container {
        position: relative;
        padding-top: 7.25rem;
        height: 100%;
    }

    .main-container {
        position: relative;
        margin-top: -9.25rem;
        padding-bottom: 4.5rem;
    }
</style>
