$badge-variations: (
    primary: $pink,
    info: $blue,
    warning: $yellow,
    danger: $rose
);

.badge {
    position: relative;
    border-radius: 50%;
    font-size: 100%;
    font-weight: 900;
    display: inline-flex;
    padding: 1.25rem;
    color: $white;
    background-color: $primary;

    .inner-badge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.locked {
        background-color: $pink;
    }

    &.completed {
        background-color: $teal;
    }

    &.skipped {
        background-color: $blue;
    }

    &.badge-sm {
        padding: 1rem;
    }

    &.badge-md {
        padding: 2.0625rem;
    }

    &.badge-xl {
        padding: 2.625rem;
        font-size: 2.625rem;
    }

    @each $version, $color in $badge-variations {
        &.badge-#{$version} {
            &.locked {
                background-color: $color;
            }
        }
    }
}

.badge + .badge {
    margin-left: 1rem;
}
